<template>
  <div class="pupils">
    <div v-if="isInModal" class="top-bar text-left">
       <i class="fas fa-times close-icon pl-4 hover:opacity-75" title="Close"  @click="$emit('close')"></i>
      <button
            @click="$emit('close')"
            type="button"
            class="publish-button hover:bg-blue-300 float-right"
          >
            Done
          </button>
    </div>

    <header class="w-95p mx-auto my-6">


      <ul class="actions right">
        <li v-if="showSearch">
          <input
            type="search"
            class="h-full w-64 bg-white rounded shadow-sm p-2 mr-3"
            name="search"
            v-model="search"
            placeholder="Enter search term..."
          />
        </li>
        <li>
          <button
            type="button"
            class="action-button icon hover:bg-blue-200 fa-search"
            v-bind:class="showSearch ? 'bg-blue-300 text-white' : ''"
            title="Search"
            @click="setSearch()"
          ></button>
        </li>
        <li>
          <button
            type="button"
            class="action-button icon hover:bg-blue-200 fa-filter"
            v-bind:class="showFilter ? 'bg-blue-300 text-white' : ''"
            title="Filter"
            @click="setFilter()"
          ></button>
        </li>
      </ul>

      <slot name="title"> 

       <p class="ml-1 pt-2 text-gray-500">
        {{ filteredList.length }} pupils in view
      </p>

      </slot>
    </header>

    <filterSection
      class="mx-6"
      :classes="classesArray"
      :fullView="true"
      :filterOpen="showFilter"
      v-if="showFilter"
      @classUpdated="updateFilteredClass"
      @yearsUpdated="updateFilteredYears"
      @typesUpdated="updateFilteredTypes"
      v-bind:class="isInModal ? 'pt-4' : ''"
    >
    </filterSection>

    <div v-if="errored" class="empty">
      <p>
        We are not able to retrieve this information at the moment, please try
        back later
      </p>
    </div>

    <div id="content-area" v-else>
      <div style="width: 100%; text-align: center" v-if="loading">
        <i class="spinner fa fa-spinner fa-spin"></i>
      </div>

      <section v-else>
        <div v-if="componentLoaded" class="grid grid-cols-4 gap-4 w-95p mx-auto">
          <div
            v-for="pupil in filteredList"
            :key="pupil.identifier"
            @click="selectPupil(pupil)"
            class="
              group
              w-full
              bg-white
              mx-auto
              rounded
              shadow-sm
              p-3
              cursor-pointer
              hover:opacity-50
            "
          >
            <template v-if="allowMultiple">
              <span v-if="inSelected(pupil)">
                <i class="fas fa-check-double text-green-300                   float-right
                  group-hover:text-green-900 group-hover:opacity-100"></i
              ></span>
            </template>
            <template v-else>
              <i
                class="
                  fas
                  fa-ellipsis-h
                  float-right
                  group-hover:text-red-900 group-hover:opacity-100
                "
              ></i>
            </template>

            <div class="flex items-center">
              <!-- <img
                class="w-12 h-12 rounded-full mr-4"
                src="https://pbs.twimg.com/profile_images/885868801232961537/b1F6H4KC_400x400.jpg"
                alt="Avatar of Jonathan Reinink"
              /> -->
              <div
                class="
                  w-14
                  h-14
                  rounded-full
                  mr-4
                  bg-gray-300
                  text-lg text-center
                  pt-3
                "
              >
                {{ getPupilInitials(pupil) }}
              </div>
              <div class="text-sm">
                <p class="text-black leading-none">{{ pupil.name }}</p>
                <p class="text-gray-500 text-xs pt-1">{{ pupil.class }}</p>
              </div>
            </div>
          </div>
        </div>
        <p class="m-5 mx-12" v-else>
          We could not find any data for your given selection.
        </p>
      </section>
    </div>
      </div>
</template>

<script>
import { mapGetters } from "vuex";
import { yearList } from "@/assets/js/utils";
import { pupilMixin } from "@/mixins/pupilMixin.js";

const filterSection = () => import("@/components/helpers/PupilFilter.vue");
const innertopbar = () => import("@/components/InnerTopBar.vue");

export default {
  name: "pupils",
  mixins: [pupilMixin],
  props: {
    selectedPupils: { type: Array, required: false },
    allowMultiple: { type: Boolean, required: false },
    isInModal: { type: Boolean, required: false },

  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    componentLoaded() {
      return this.filteredList.length > 0;
    },
    showMessage() {
      return this.msg != undefined && this.msg.length > 0;
    },
    orderedPupils() {
      var vm = this;
      let years = vm.filteredYears;
      let types = vm.filteredTypes;
      var classChosen = vm.filteredClass;

      return this.filterPupils(years, types, classChosen);
    },
    filteredList() {
      return this.orderedPupils.filter((item) => {
        var vm = this;
        return item.name.toLowerCase().includes(vm.search.toLowerCase());
      });
    },
  },
  data() {
    return {
      showFilter: true,
      filteredYears: [],
      filteredTypes: [],
      search: "",
      filteredClass: {},
      years: [],
      showSearch: true,
      loading: false,
      errored: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    setFilter() {
      this.showFilter = !this.showFilter;
      if (this.showFilter == false) {
        this.filteredTypes = [];
        this.filteredYears = [];
        this.filteredClass = this.classesArray[0];
      }
    },
    setSearch() {
      this.showSearch = !this.showSearch;
      this.search = "";
    },
    updateFilteredClass(value) {
      this.filteredClass = value;
    },
    updateFilteredYears(values) {
      this.filteredYears = values;
    },
    updateFilteredTypes(values) {
      this.filteredTypes = values;
    },
    getPupilInitials(pupil) {
      return pupil.name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .substring(0, 3);
    },
    inSelected(pupil) {
      let found = this.selectedPupils.find(
        (l) => l.identifier === pupil.identifier
      );
      return found;
    },
    selectPupil(pupil) {
      this.$emit("pupil-selected", pupil);
    },
  },
  components: {
    filterSection,
    innertopbar
  },
};
</script>
